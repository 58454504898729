<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <m-array-form
        v-model="value.items"
        name="Menu Item"
      >
        <template #[`item.icon`]="{ item }">
          <v-icon large v-text="`mdi-${item.icon}`" />
        </template>
        <template #[`item.title`]="{ item }">
          {{ item.title }}: <span class="grey--text">{{ item.description }}</span>
        </template>
        <template #[`item.subtitle`]="{ item }">
          {{ ($store.getters.layoutPageById(item.pageId) || {}).name }}
        </template>
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Icon"
          >
            <v-text-field
              v-model="editItem.icon"
              label="Icon"
              outlined
              hint="Icon name from material design icons."
              type="text"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              label="Title"
              outlined
              type="text"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <v-text-field
            v-model="editItem.description"
            label="Description"
            outlined
            hint="Optional description to show below the title."
            type="text"
          />
          <validation-provider
            v-slot="{ errors }"
            name="Page to open"
            rules="required"
          >
            <v-select
              v-model="editItem.pageId"
              :items="availablePages"
              :error-messages="errors"
              outlined
              item-text="name"
              item-value="id"
              label="Page to open"
              required
            />
          </validation-provider>
        </template>
      </m-array-form>
      <field-card label="Design & Display">
        <v-switch
          v-model="value.dark"
          label="Dark Mode"
        />
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MMenuForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    layoutId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    error: null
  }),
  computed: {
    availablePages() {
      const pages = this.$store.getters.layoutPages
      if (this.layoutId) {
        return pages.filter(x => x.layoutId === this.layoutId)
      }
      return pages
    }
  }
}
</script>
