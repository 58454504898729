var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5"},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('m-widget-container-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('m-array-form',{attrs:{"name":"Menu Item"},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(("mdi-" + (item.icon)))}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+": "),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.description))])]}},{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.$store.getters.layoutPageById(item.pageId) || {}).name)+" ")]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Icon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Icon","outlined":"","hint":"Icon name from material design icons.","type":"text","required":"","error-messages":errors},model:{value:(editItem.icon),callback:function ($$v) {_vm.$set(editItem, "icon", $$v)},expression:"editItem.icon"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Title","outlined":"","type":"text","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Description","outlined":"","hint":"Optional description to show below the title.","type":"text"},model:{value:(editItem.description),callback:function ($$v) {_vm.$set(editItem, "description", $$v)},expression:"editItem.description"}}),_c('validation-provider',{attrs:{"name":"Page to open","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.availablePages,"error-messages":errors,"outlined":"","item-text":"name","item-value":"id","label":"Page to open","required":""},model:{value:(editItem.pageId),callback:function ($$v) {_vm.$set(editItem, "pageId", $$v)},expression:"editItem.pageId"}})]}}],null,true)})]}}],null,true),model:{value:(_vm.value.items),callback:function ($$v) {_vm.$set(_vm.value, "items", $$v)},expression:"value.items"}}),_c('field-card',{attrs:{"label":"Design & Display"}},[_c('v-switch',{attrs:{"label":"Dark Mode"},model:{value:(_vm.value.dark),callback:function ($$v) {_vm.$set(_vm.value, "dark", $$v)},expression:"value.dark"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }